import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../config/axios.config'
import { axiosHeaders } from '../../utils'

export const updateScore = createAsyncThunk<
  any,
  {},
  { rejectValue: string | null }
>('customer/update', async (params, thunkApi) => {
  try {
    const response: any = await axios.post(
      '/customer/score/update',
      params,
      axiosHeaders()
    )

    if (response.status === 200 && response.data.response === 'success') {
      return { status: 'success' }
    } else {
      return thunkApi.rejectWithValue(response.data.meta.errorDescription)
    }
  } catch (error: any) {
    if (error.response.data.response) {
      return thunkApi.rejectWithValue(error.response.data.response)
    }

    return thunkApi.rejectWithValue(error.message)
  }
})

export const getCustomerScoreDetails = createAsyncThunk<
  any,
  string,
  { rejectValue: string | null }
>('customer/scoreDetails', async (params, thunkAPI) => {
  let digiScoreResponse,
    digiScoreError: any,
    iScoreResponse,
    iScoreError: any,
    celcomScoreResponse,
    celcomScoreError: any,
    cdScoreResponse,
    cdScoreError: any

  try {
    digiScoreResponse = await axios.get(
      '/customer/DIGI%23' + params,
      axiosHeaders()
    )
  } catch (error) {
    digiScoreError = error
  }

  try {
    celcomScoreResponse = await axios.get(
      '/customer/CELCOM%23' + params,
      axiosHeaders()
    )
  } catch (error) {
    celcomScoreError = error
  }

  try {
    cdScoreResponse = await axios.get(
      '/customer/CD%23' + params,
      axiosHeaders()
    )
  } catch (error) {
    cdScoreError = error
  }

  try {
    iScoreResponse = await axios.get(
      '/customer/EXPERIAN%23' + params,
      axiosHeaders()
    )
  } catch (error) {
    iScoreError = error
  }

  if (digiScoreError && iScoreError && celcomScoreError && cdScoreError) {
    return thunkAPI.rejectWithValue(
      digiScoreError.response.data.meta.errorDescription
    )
  }

  const digiScoreData = digiScoreResponse?.data?.response || [],
    iScoreData = iScoreResponse?.data?.response || [],
    celcomScoreData = celcomScoreResponse?.data?.response || [],
    cdScoreData = cdScoreResponse?.data?.response || []

  const data = [].concat(digiScoreData, iScoreData, celcomScoreData, cdScoreData)

  return { data }
})

export const getCustomerEligibleCombos = createAsyncThunk<
  any,
  {},
  { rejectValue: string | null }
>('customer/eligibleCombos', async (params, thunkAPI) => {
  try {
    const response: any = await axios.post(
      '/customer/eligibleCombo',
      params,
      axiosHeaders()
    )

    if (response.status === 200) {
      return { data: response.data.response }
    } else {
      return thunkAPI.rejectWithValue(response.data.meta.errorDescription)
    }
  } catch (error: any) {
    if (error.response.data.response) {
      return thunkAPI.rejectWithValue(error.response.data.response)
    }

    return thunkAPI.rejectWithValue(error.message)
  }
})

export const getDigiHistory = createAsyncThunk<
  any,
  string,
  { rejectValue: string | null }
>('customer-details/digihistory', async (params, thunkAPI) => {
  try {
    const response: any = await axios.get(
      '/auditTrail/Customer%23' + params,
      axiosHeaders()
    )

    if (response.status === 200) {
      return { data: response.data.response }
    } else {
      return thunkAPI.rejectWithValue(response.meta.errorDescription)
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.meta.errorDescription)
  }
})

export const getExperianHistory = createAsyncThunk<
  any,
  string,
  { rejectValue: string | null }
>('customer-details/experianHistory', async (params, thunkAPI) => {
  try {
    const response: any = await axios.get(
      '/auditTrail/Customer%23' + params,
      axiosHeaders()
    )

    if (response.status === 200) {
      return { data: response.data.response }
    } else {
      return thunkAPI.rejectWithValue(response.meta.errorDescription)
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.meta.errorDescription)
  }
})

export const getDigiScoreDetails = createAsyncThunk<
  any,
  { label: string },
  { rejectValue: string | null }
>('customer/getDigiScoreDetails', async (params, thunkAPI) => {
  try {
    const response: any = await axios.get(
      '/score/' + params.label,
      axiosHeaders()
    )

    if (response.status === 200) {
      return { data: response.data.response }
    } else {
      return thunkAPI.rejectWithValue(response.meta.errorDescription)
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.response)
  }
})

export const getFinalScoreDetails = createAsyncThunk<
  any,
  { label: string },
  { rejectValue: string | null }
>('customer/getFinalScoreDetails', async (params, thunkAPI) => {
  try {
    const response: any = await axios.get(
      '/score/' + params.label,
      axiosHeaders()
    )

    if (response.status === 200) {
      return { data: response.data.response }
    } else {
      return thunkAPI.rejectWithValue(response.meta.errorDescription)
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.response)
  }
})
